import request from '@/utils/request'

// 用户注册
export function doRegister(params) {
    return request({
        url: '/v1/mall/user/register',
        method: 'post',
        data: params
    })
}

// 获取图片验证码
export function getImgCode() {
    return request({
        url: '/v1/mall/easy',
        method: 'get'
    })
}

// 获取短信验证码
export function SendSms(params) {
    return request({
        url: '/v1/mall/send',
        method: 'post',
        data: params
    })
}

// 协议内容
export function getRule(id) {
    return request({
        url: '/v1/mall/rule?id=' + id,
        method: 'get'
    })
}

// 登录
export function userLogin(params) {
    return request({
        url: '/v1/mall/user/login',
        method: 'post',
        data: params
    })
}

// 忘记密码
export function doforget(params) {
    return request({
        url: '/v1/mall/user/forget',
        method: 'post',
        data: params
    })
}

// 用户信息
export function getInfo() {
    return request({
        url: '/v1/mall/user/info',
        method: 'get',
    })
}
