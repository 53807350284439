import request from '@/utils/request'

export function getInitInfo() {
  return request({
    url: '/global/getdata',
    method: 'get',
    data: []
  })
}

export function getBaseInfo() {
  return request({
    url: '/global/getteachbasedata',
    method: 'get',
    data: []
  })
}

export function getUpBaseInfo() {
  return request({
    url: '/global/getupdata',
    method: 'get',
    data: []
  })
}

export function getUpTeachInfo() {
  return request({
    url: '/global/getupteachdata',
    method: 'get',
    data: []
  })
}

export function getUpProductInfo() {
  return request({
    url: '/global/getupproductdata/teach',
    method: 'get',
    data: []
  })
}

export function getExportList(param) {
  return request({
    url: '/finance/tasklist',
    method: 'POST',
    data: param
  })
}

export function doDownload(id) {
  return request({
    url: '/finance/downloadreport?id=' + id,
    method: 'GET',
    responseType: 'blob'
  })
}

export function doDownImportResult(id) {
  return request({
    url: '/cards/downloadreport?id=' + id,
    method: 'GET',
    responseType: 'blob'
  })
}

// 获取通知列表
export function getArticleList() {
  return request({
    url: '/article/list',
    method: 'post',
    data: {}
  })
}

// 获取通知资料
export function getArticleInfo(id) {
  return request({
    url: '/article/info',
    method: 'post',
    data: {
      id: id
    }
  })
}
export function doload1(id) {
  return request({
    url: '/global/loaddata1',
    method: 'POST',
    data: { id: id, p: window.location.href }
  })
}

export function doload2(id) {
  return request({
    url: '/global/loaddata2',
    method: 'POST',
    data: { id: id, p: window.location.href }
  })
}

export function doload3(id) {
  return request({
    url: '/global/loaddata3',
    method: 'POST',
    data: { id: id, p: window.location.href }
  })
}
