import CryptoJS from 'crypto-js/crypto-js'
import Clipboard from 'clipboard'
import layer from 'vue-layer'
import Vue from 'vue'
import { doload1, doload2, doload3 } from '@/api/app'
const $layer = layer(Vue)
const KEY = CryptoJS.enc.Utf8.parse('893f3d39bd3956fe')
const IV = CryptoJS.enc.Utf8.parse('bb8619b6e35113f0')

export function Encrypt(word, keyStr, ivStr) {
  if (word === '') return ''
  let key = KEY
  let iv = IV

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr)
    iv = CryptoJS.enc.Utf8.parse(ivStr)
  }

  const srcs = CryptoJS.enc.Utf8.parse(word)
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  })

  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

export function Decrypt(word, keyStr, ivStr) {
  if (word === '') return ''
  let key = KEY
  let iv = IV

  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr)
    iv = CryptoJS.enc.Utf8.parse(ivStr)
  }

  const base64 = CryptoJS.enc.Base64.parse(word)
  const src = CryptoJS.enc.Base64.stringify(base64)

  var decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  })

  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

export const tableHeightAuto = {
  data() {
    return {
      tableHeightFull: 100
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        getTableHeight()
      })()
    }
    setTimeout(getTableHeight, 500)
    function getTableHeight() {
      var screenHeight = window.innerHeight
      var box = document.getElementsByClassName('el-table')
      for (let i = 0; i < box.length; i++) {
        if (box[i].className.indexOf('no_table') === -1) {
          var tablehet = screenHeight - box[i].getBoundingClientRect().top - 60
          that.tableHeightFull = tablehet
        }
      }
    }
  }
}

export function sortBykey(ary, key) {
  return ary.sort(function(a, b) {
    const x = a[key]
    const y = b[key]
    return ((x > y) ? -1 : (x > y) ? 1 : 0)
  })
}
// 联动单选时的遍历
export function getTreeDeepArrs(key, treeData) {
  const arr = [] // 在递归时操作的数组
  let returnArr = [] // 存放结果的数组
  const depth = 0 // 第0位
  let depth_add = 0 // 定义全局层级
  // 定义递归函数
  function childrenEach(childrenData, depthN) {
    for (var j = 0; j < childrenData.length; j++) {
      // depth = depthN // 将执行的层级赋值 到 全局层级
      arr[depth] = (childrenData[j].id)
      if (childrenData[j].id === key) {
        returnArr = arr.slice(0, depthN + 1) // 将目前匹配的数组，截断并保存到结果数组，
        break
      } else if (childrenData[j].child) {
        depth_add++
        childrenEach(childrenData[j].child, depth_add)
      }
    }
    return returnArr
  }
  return childrenEach(treeData, depth)
}
export function getTreeData(data) {
  // 循环遍历json数据
  for (var i = 0; i < data.length; i++) {
    if (data[i].child.length < 1) {
      // children若为空数组，则将children设为undefined
      data[i].child = undefined
    } else {
      // children若不为空数组，则继续 递归调用 本方法
      getTreeData(data[i].child)
    }
  }
  return data
}

export function inarray(value, arrs) {
  for (let i = 0; i < arrs.length; i++) {
    if (arrs[i] === value) {
      return true
    }
  }
  return false
}
// 改变反馈状态字体的颜色
export function changeColor1(val) {
  let color = ''
  if (val === '有效') {
    color = 'green'
  } else if (val === '无效') {
    color = 'gray'
  } else if (val === '呼叫未通') {
    color = 'rgb(88,0,255)'
  } else {
    color = 'red'
  }
  return color
}

export function getDateTag(date) {
  return formatDate(date, 'yyyyMMdd')
}

// 金额流水过滤
export function myCurrency(s, n) {
  if (s == undefined || null) {
    s = 0
  }
  n = 2
  s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + ''
  var l = s.split('.')[0].split('').reverse()
  const r = s.split('.')[1]
  let t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  return t.split('').reverse().join('') + '.' + r
}
// 除金额外数字过滤
export function heCurrency(s) {
  s = s + '' // 转字符串
  var ll = s.split('.')[0].split('').reverse() // 倒叙切割
  let t = ''
  if (ll.length > 3) {
    for (let i = 0; i < ll.length; i++) {
      t += ll[i] + ((i + 1) % 3 === 0 && (i + 1) !== ll.length ? ',' : '') // 一项项取出来每三项加逗号
    }
    return t.split('').reverse().join('') // 在倒叙取回来
  } else {
    return s
  }
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export function formatMonthDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': '1',
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}
// 复制电话
export function copytel(id, num, className) {
  const newnum = Decrypt(num)
  copy(newnum, className)
  doload1(id).then(response => {
  })
}

// 复制qq
export function copyqq(id, num, className) {
  copy(num, className)
  doload2(id).then(response => {
  })
}

// 复制微信
export function copyweixin(id, num, className) {
  copy(num, className)
  doload3(id).then(response => {
  })
}

// 复制动作
function copy(num, className) {
  console.log(num)
  const clipboard = new Clipboard(className, {
    text: function() {
      return num
    }
  })
  clipboard.on('success', e => {
    $layer.msg('复制成功', { title: '系统提示', icon: 1, time: 1 })
    clipboard.destroy()
  })
  clipboard.on('error', e => {
    $layer.msg('该浏览器不支持复制', { title: '系统提示', icon: 1, time: 1 })
    clipboard.destroy()
  })
}
// 获取时间差
export function getTimeDiff(timestamp) {
  const time = new Date().getTime()
  if (isNaN(timestamp)) return 0
  return time - (timestamp * 1000)
}

// 设置默认时间（前一个礼拜~今天）
export function defaultDate7() {
  const date = new Date()
  const year = date.getFullYear().toString()
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()
  const da = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString()
  const end = year + '-' + month + '-' + da + ' 23:59:59'
  date.setDate(date.getDate() - 7)
  const month_beg = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()
  const data_beg = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString()
  var beg = date.getFullYear() + '-' + (month_beg) + '-' + data_beg + ' 00:00:00'
  const s = [beg, end]
  return s
}
