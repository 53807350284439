import { getInitInfo } from '@/api/app'
import { getBaseInfo } from '@/api/app'
import { getUpBaseInfo, getUpTeachInfo, getUpProductInfo } from '@/api/app'
import { sortBykey, getTimeDiff } from '@/utils/common'
import { setTimeSpan } from '@/utils/auth'
import store from '..'

const app = {
  state: {
    gdata: {
      areas: [],
      intents: [],
      products: [],
      lastAssignType: [],
      educations: [],
      sexes: [],
      times: [],
      netStatus: [],
      dictMap: {
        WSEDU_ALLOT_STATUS_CODE: [],
        WSEDU_IS_FEEDBACK_CODE: [],
        WSEDU_VALID_CODE: [],
        WSEDU_CUSTOMER_STATUS: [],
        WSEDU_RESV_STATUS_CODE: [],
        HC_WSEDU_DEPOSIT_STATUS_CODE: [],
        HC_WSEDU_ORDER_STATUS_CODE: []
      }
    },
    binfover: '',
    tinfover: '',
    pinfover: '',
    callPhone: '',
    callPhone2: '',
    boardRefresh: true,
    systemData: []
  },
  mutations: {
    SET_GDATA: (state, datas) => {
      state.gdata = datas
    },
    SET_CALLPHONE: (state, phone) => {
      state.callPhone = phone
    },
    SET_CALLPHONE2: (state, phone) => {
      state.callPhone2 = phone
    },
    SET_BINFOVER: (state, ver) => {
      state.binfover = ver
    },
    SET_TINFOVER: (state, ver) => {
      state.tinfover = ver
    },
    SET_PINFOVER: (state, ver) => {
      state.pinfover = ver
    },
    SET_BOARDREFRESH: (state, boardRefresh) => {
      state.boardRefresh = boardRefresh
    },
    SET_COPYFUNC: (state, value) => {
      state.copyfunc = value
    },
    SET_SYSTEMDATA: (state, value) => {
      state.systemData = value
    }
  },

  actions: {
    // 全局内容
    getInitInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInitInfo().then(response => {
          commit('SET_SYSTEMDATA', response.data.systemData)
          commit('SET_COPYFUNC', response.data.systemData.copyfunc)
          setTimeSpan(getTimeDiff(response.timestamp))
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getBaseInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getBaseInfo().then(response => {
          if (response === undefined || response.code !== 200) return
          const data = response.data
          data.intents = sortBykey(data.intents, 'seq')
          commit('SET_GDATA', data)
          commit('SET_SYSTEMDATA', response.data.systemData)
          commit('SET_COPYFUNC', response.data.systemData.copyfunc)
          commit('SET_BINFOVER', response.binfover)
          commit('SET_TINFOVER', response.tinfover)
          commit('SET_PINFOVER', response.pinfover)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUpBaseInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUpBaseInfo().then(response => {
          if (response === undefined || response.code !== 200) return
          const data = store.state.app.gdata
          data.systemData = response.data.systemData
          data.settingsales = response.data.settingsales
          data.areas = response.data.areas
          data.products = response.data.products
          data.productroots = response.data.productroots
          data.ProductResvFlag = response.data.ProductResvFlag
          data.productTags = response.data.productTags
          data.sexes = response.data.sexes
          data.users = response.data.users
          data.userrole = response.data.userrole
          data.invalidCodes = response.data.invalidCodes
          data.residences = response.data.residences
          data.documentTypes = response.data.documentTypes
          data.educations = response.data.educations
          data.educationsTypes = response.data.educationsTypes
          data.diplomaStatuss = response.data.diplomaStatuss
          data.treeOrgs = response.data.treeOrgs
          data.orgTypes = response.data.orgTypes
          data.schools = response.data.schools
          data.netStatus = response.data.netStatus
          data.qqNetStatus = response.data.qqNetStatus
          data.lastAssignType = response.data.lastAssignType
          data.treeAreas = response.data.treeAreas
          data.mscCodes = response.data.mscCodes
          data.gradeList = response.data.gradeList
          data.careas = response.data.careas
          data.tags = response.data.tags
          data.payTypes = response.data.payTypes
          data.payStatuss = response.data.payStatuss
          data.orderStatus = response.data.orderStatus
          data.userLevel = response.data.userLevel
          data.superReview = response.data.superReview
          data.orderTypes = response.data.orderTypes
          data.intents = sortBykey(response.data.intents, 'seq')

          commit('SET_GDATA', data)
          commit('SET_SYSTEMDATA', response.data.systemData)
          commit('SET_COPYFUNC', response.data.systemData.copyfunc)
          commit('SET_BINFOVER', response.binfover)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUpTeachInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUpTeachInfo().then(response => {
          if (response === undefined || response.code !== 200) return
          const data = store.state.app.gdata
          data.productservices = response.data.productservices
          data.productTags = response.data.productTags
          data.studentStatus = response.data.studentStatus
          data.teachMasters = response.data.teachMasters
          data.proServicesTags = response.data.proServicesTags
          data.proServicesItems = response.data.proServicesItems
          data.teachMajorsFull = response.data.teachMajorsFull
          commit('SET_GDATA', data)
          commit('SET_SYSTEMDATA', response.data.systemData)
          commit('SET_COPYFUNC', response.data.systemData.copyfunc)
          commit('SET_TINFOVER', response.tinfover)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUpProductInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUpProductInfo().then(response => {
          if (response === undefined || response.code !== 200) return
          const data = store.state.app.gdata
          data.products = response.data.products
          data.productroots = response.data.productroots
          data.commodityroots = response.data.commodityroots
          data.commoditys = response.data.commoditys
          data.commodityCates = response.data.commodityCates
          data.classes = response.data.classes
          data.majors = response.data.majors
          data.majorsfull = response.data.majorsfull
          commit('SET_GDATA', data)
          commit('SET_SYSTEMDATA', response.data.systemData)
          commit('SET_COPYFUNC', response.data.systemData.copyfunc)
          commit('SET_PINFOVER', response.pinfover)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default app
