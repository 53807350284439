const routes = [
    {
        path: '/',
        component: resolve => require(["@/views/Home.vue"], resolve),
        redirect: '/homePage/index',
        children: [
            {
                path: "/homePage/index",
                name: "index",
                component: resolve => require(["@/views/pages/homePage/index.vue"], resolve),
            },
            {
                path: "/classify/index",
                name: "classify",
                component: resolve => require(["@/views/pages/classify/index.vue"], resolve),
            },
            {
                path: "/shoppings/index",
                name: "index",
                component: resolve => require(["@/views/pages/shoppings/index.vue"], resolve),
            },
            {
                path: "/my/index",
                name: "index",
                component: resolve => require(["@/views/pages/my/index.vue"], resolve),
            },
            {
                path: "/goods/detail",
                name: "goodsdetail",
                component: resolve => require(["@/views/pages/goods/detail.vue"], resolve),
            },
            {
                path: "/order/list",
                name: "orderlist",
                component: resolve => require(["@/views/pages/order/list.vue"], resolve),
            },
            {
                path: "/order/aftermarketList",
                name: "aftermarketList",
                component: resolve => require(["@/views/pages/order/aftermarketList.vue"], resolve),
            },
            {
                path: "/order/aftermarketType",
                name: "aftermarketType",
                component: resolve => require(["@/views/pages/order/aftermarketType.vue"], resolve),
            },
            {
                path: "/order/detail",
                name: "orderdetail",
                component: resolve => require(["@/views/pages/order/detail.vue"], resolve),
            },
            {
                path: "/order/logistics", // 物流消息
                name: "logistics",
                component: resolve => require(["@/views/pages/order/logistics.vue"], resolve),
            },
            {
                path: "/login/index",
                name: "loginIndex",
                component: resolve => require(["@/views/pages/login/index.vue"], resolve),
            },
            {
                path: "/login/register", // 注册
                name: "register",
                component: resolve => require(["@/views/pages/login/register.vue"], resolve),
            },
            {
                path: "/login/backPassword", // 找回密码
                name: "backPassword",
                component: resolve => require(["@/views/pages/login/backPassword.vue"], resolve),
            },
            {
                path: "/login/codeLogin", // 短信验证码
                name: "codeLogin",
                component: resolve => require(["@/views/pages/login/codeLogin.vue"], resolve),
            },
            {
                path: "/login/codeIndex", // 输入验证码
                name: "codeIndex",
                component: resolve => require(["@/views/pages/login/codeIndex.vue"], resolve),
            },
            {
                path: "/order/address", // 收货地址
                name: "addresss",
                component: resolve => require(["@/views/pages/order/address.vue"], resolve),
            },
            {
                path: "/order/editAddress", // 编辑地址
                name: "editAddress",
                component: resolve => require(["@/views/pages/order/editAddress.vue"], resolve),
            },
            {
                path: "/my/collectlist", // 收藏浏览列表
                name: "collectlist",
                component: resolve => require(["@/views/pages/my/collectlist.vue"], resolve),
            },
            {
                path: "/service/returns", // 退货申请
                name: "returns",
                component: resolve => require(["@/views/pages/service/returns.vue"], resolve),
            },
            {
                path: "/service/barter", // 换货申请
                name: "barter",
                component: resolve => require(["@/views/pages/service/barter.vue"], resolve),
            },
            {
                path: "/service/serviceOrderDetail", // 服务订单详情
                name: "serviceOrderDetail",
                component: resolve => require(["@/views/pages/service/serviceOrderDetail.vue"], resolve),
            },
            {
                path: "/service/schedule", // 进度详情
                name: "schedule",
                component: resolve => require(["@/views/pages/service/schedule.vue"], resolve),
            },
            {
                path: "/service/serviceCancel", // 服务订单取消
                name: "serviceCancel",
                component: resolve => require(["@/views/pages/service/serviceCancel.vue"], resolve),
            },
            {
                path: "/my/openDeal", // 协议
                name: "openDeal",
                component: resolve => require(["@/views/pages/my/openDeal.vue"], resolve),
            }
        ]
    },
    {
        path: '/login',
        component: resolve => require(["@/views/login.vue"], resolve),
    }
]
export default routes