<template>
  <div/>
</template>
<script>
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'

export default{
  install(Vue, options) {
    const $layer = layer(Vue)
    Vue.prototype.$layer = {
      alert(content, yescallback, icon, title = '系统提示') {
        $layer.alert(content, {
          icon: icon,
          title: title
        }, (index) => {
          $layer.close(index)
          if (yescallback) yescallback()
        })
      },
      confirm(content, title, yescallback, cancelcallback) {
        $layer.confirm(content, {
          title: title
        }, (index) => {
          $layer.close(index)
          if (yescallback) yescallback()
        }, () => {
          $layer.closeAll()
          if (cancelcallback) cancelcallback()
        }
        )
      },
      quicktips(content) {
        $layer.msg(content, {
          time: 2
        })
      },
      tips(content, id, index, color) {
        $layer.tips(content, id, {
          tips: [index, color]
        })
      },
      open(parent, content, options, width, height, title) {
        $layer.open({
          content: {
            content: content,
            parent: parent,
            data: options
          },
          area: [width, height],
          title: title,
          shadeClose: false,
          type: 2
        })
      },
      loading(content = '正在加载中') {
        $layer.loading({
          type: 3,
          content: 'Loading',
          spinner: 'el-icon-loading'
        })
      },
      close() {
        $layer.closeAll()
      }
    }
  }
}

</script>

<style scoped>
  .el-button {
    background-color:  #04c595;
  }
 .vl-notify-content{
    margin-top: 50px !important
  }
</style>
