// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from "@/router/index.js" //引入路由器
Vue.config.productionTip = false

// 把vuex仓库引入并挂载在Vue实例上
import store from './store/index'
// 导入msg提示框
import Dialog from '@/views/components/dialog/dialog.vue'
Vue.use(Dialog)
// 导入公共样式
import '@/assets/css/public.css'

import bottomList from '@/views/components/business/bottomList' // 沉底的跳转
Vue.component('bottomList', bottomList)

// 在src/main.js进行全局引入
import Vant from 'vant'
import 'vant/lib/index.css';
Vue.use(Vant)

import echarts from 'echarts'
Vue.prototype.$echarts = echarts


let vvvue = new Vue({
  render: h => h(App),
  router,
  store // 挂载上去
}).$mount('#app')

// console.log('vue实例对象',vvvue);
