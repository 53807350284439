import Cookies from 'js-cookie'

const TokenKey = 'educationtis-Token'
const TagKey = 'educationtis-TimeTag'
const TimespanKey = 'educationtis-TimeSpan'
const RedirectKey = 'educationtis-Redirect'
const IdcardKey = 'educationtis-IdcardTag'
const NeedValidKey = 'educationtis-needValid'

export function getToken1() {
  return Cookies.get(TokenKey)
}

export function setToken1(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken1() {
  return Cookies.remove(TokenKey)
}

export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

export function getRedirect() {
  return sessionStorage.getItem(RedirectKey)
}

export function setRedirect(redirect) {
  return sessionStorage.setItem(RedirectKey, redirect)
}

export function removeRedirect() {
  return sessionStorage.removeItem(RedirectKey)
}
export function getTimeTag() {
  if (!sessionStorage.getItem(TagKey) || sessionStorage.getItem(TagKey) === 'undefined') {
    return ''
  }
  return sessionStorage.getItem(TagKey)
}

export function setTimeTag(timeTag) {
  return sessionStorage.setItem(TagKey, timeTag)
}

export function removeTimeTag() {
  return sessionStorage.removeItem(TagKey)
}

export function getTimeSpan() {
  if (!sessionStorage.getItem(TimespanKey) || sessionStorage.getItem(TimespanKey) === 'undefined') {
    return 0
  }

  let timespan = sessionStorage.getItem(TimespanKey)
  timespan = isNaN(timespan) ? 0 : parseInt(timespan)
  return timespan
}

export function setTimeSpan(timespan) {
  return sessionStorage.setItem(TimespanKey, timespan)
}

export function removeTimeSpan() {
  return sessionStorage.removeItem(TimespanKey)
}

export function getClientTag() {
  return localStorage.getItem(IdcardKey)
}

export function setClientTag(idcard) {
  return localStorage.setItem(IdcardKey, idcard)
}

export function getNeedValid() {
  return Number(sessionStorage.getItem(NeedValidKey))
}
export function setNeedValid(needValid) {
  return sessionStorage.setItem(NeedValidKey, needValid)
}
export function removeNeedValid() {
  return sessionStorage.removeItem(NeedValidKey)
}
